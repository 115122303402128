/* tslint:disable */
/* eslint-disable */
/**
 * CGN Onboarding Portal Public API
 * Carta Giovani Nazionale Onboarding Portal - Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface HelpRequest
 */
export interface HelpRequest {
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    referentFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    referentLastName: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    category: HelpRequestCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    topic?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    recaptchaToken: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HelpRequestCategoryEnum {
    Access = 'Access',
    DataFilling = 'DataFilling',
    Discounts = 'Discounts',
    Documents = 'Documents',
    TechnicalProblem = 'TechnicalProblem',
    CgnOwnerReporting = 'CgnOwnerReporting',
    Suggestions = 'Suggestions',
    Other = 'Other'
}


/**
 * HelpApi - axios parameter creator
 * @export
 */
export const HelpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send an help request
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendHelpRequest: async (helpRequest: HelpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'helpRequest' is not null or undefined
            assertParamExists('sendHelpRequest', 'helpRequest', helpRequest)
            const localVarPath = `/help`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(helpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpApi - functional programming interface
 * @export
 */
export const HelpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send an help request
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendHelpRequest(helpRequest: HelpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendHelpRequest(helpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpApi - factory interface
 * @export
 */
export const HelpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpApiFp(configuration)
    return {
        /**
         * 
         * @summary Send an help request
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendHelpRequest(helpRequest: HelpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendHelpRequest(helpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpApi - object-oriented interface
 * @export
 * @class HelpApi
 * @extends {BaseAPI}
 */
export class HelpApi extends BaseAPI {
    /**
     * 
     * @summary Send an help request
     * @param {HelpRequest} helpRequest Help request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    public sendHelpRequest(helpRequest: HelpRequest, options?: any) {
        return HelpApiFp(this.configuration).sendHelpRequest(helpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


